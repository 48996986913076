import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <Seo title="404 - Página não encontrada" />
    <div class="flex items-center justify-center h-screen bg-gray-200">
      <div class="container">
        <div class="bg-white rounded-lg shadow-lg p-5 md:p-20 mx-2">
          <div class="text-center">
            <h2 class="text-4xl tracking-tight leading-10 font-extrabold text-gray-900 sm:text-5xl sm:leading-none md:text-6xl">
              Ops <span class="text-indigo-600">404</span>
            </h2>
            <p class="text-md md:text-xl mt-10">Página não encontrada</p>
          </div>
        </div>
      </div>
    </div>
  </Layout>
)

export default NotFoundPage
